import useGuestEventActions from "@/composables/guest/event/useGuestEventActions.js";

export const EventAccessFailedMiddleware = async ({ to, next }) => {
	const id = to.params.id ?? "";
	const slug = to.params.slug ?? "";
	const token = to.params.token ?? "";

	const { fetch, isPrivate } = useGuestEventActions();
	try {
		await fetch(id, slug, token);
	} catch (e) {}

	if (!isPrivate.value) {
		return {
			name: "event",
			params: {
				locale: to.params.locale,
				id: id,
				slug: slug,
				token: token,
			},
		};
	}

	return await next();
};

export default EventAccessFailedMiddleware;
