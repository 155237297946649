import useRepository from "@@/composables/useRepository.js";
const resource = "events";

export const getEvent = (id, slug, token = "") => {
	return useRepository().getRaw(`${resource}/${id}/landing-page/${slug}${token ? `/${token}` : ""}`);
};

export const verificationEmail = (id, slug, email) => {
	const data = {
		email,
	};
	return useRepository().post(`${resource}/${id}/landing-page/${slug}/email-verification`, data);
};

export const verificationCode = (code, token) => {
	const data = {
		code,
		token,
	};
	return useRepository().post(`${resource}/landing-page/code-verification`, data);
};

export default {
	getEvent,
	verificationEmail,
	verificationCode,
};
