import { RouteEnum } from "@@/enums/RouteEnum.js";

export const EventParamsExistsMiddleware = async ({ to, next }) => {
	const id = to.params.id ?? "";
	const slug = to.params.slug ?? "";

	if (!id || !slug) {
		return { name: RouteEnum.ERROR_404, params: { locale: to.params.locale } };
	}

	return await next();
};

export default EventParamsExistsMiddleware;
