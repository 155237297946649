import RouteExistsMiddleware from "@@/middlewares/RouteExistsMiddleware";
import LocaleExistsMiddleware from "@@/middlewares/LocaleExistsMiddleware";
import EventParamsExistsMiddleware from "@/middlewares/EventParamsExistsMiddleware.js";
import EventAccessPassedMiddleware from "@/middlewares/EventAccessPassedMiddleware.js";
import EventAccessFailedMiddleware from "@/middlewares/EventAccessFailedMiddleware.js";
import errorRoutes from "@/routes/error";

const routes = [
	{
		path: "",
		redirect: {
			name: "main",
			params: {
				locale: "pl",
			},
		},
	},
	{
		name: "main",
		path: `/:locale`,
		component: () => import("@/views/Index.vue"),
		meta: {
			middleware: [LocaleExistsMiddleware],
			breadcrumbs: {
				hidden: true,
			},
		},
		children: [
			{
				name: "home",
				path: "",
				component: () => import("@/views/pages/home/Home.vue"),
				meta: {
					layout: "clear",
				},
			},
			{
				name: "event",
				path: "event/:id?/:slug?/:token?",
				component: () => import("@/views/pages/event/Event.vue"),
				meta: {
					middleware: [EventParamsExistsMiddleware, EventAccessPassedMiddleware],
					layout: "default",
				},
			},
			{
				name: "eventAccessVerification",
				path: "event-access-verification/:id/:slug",
				component: () => import("@/views/pages/event/verification/EventVerification.vue"),
				meta: {
					middleware: [EventAccessFailedMiddleware],
					layout: "clear",
				},
			},
			{
				name: "confirmParticipation",
				path: "confirm-participation",
				component: () => import("@/views/pages/confirm_participation/ConfirmParticipation.vue"),
				meta: {
					layout: "clear",
				},
			},
			// {
			// 	name: "demo",
			// 	path: "demo",
			// 	component: () => import("@/views/pages/demo/Demo.vue"),
			// 	meta: {
			// 		layout: "default",
			// 	},
			// },
			{
				name: "errors",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					layout: "error",
				},
				children: [...errorRoutes],
			},
		],
	},
	{
		path: "/:path(.*)*",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [LocaleExistsMiddleware, RouteExistsMiddleware],
		},
	},
];

export default routes;
