import { defineStore } from "pinia";
import GuestEventRepository from "@/repositories/guest/GuestEventRepository.js";

export const useGuestEventStore = defineStore("guestEvent", {
	state: () => ({
		event: {},
		isPrivate: {},
		token: {},
	}),

	getters: {
		getEvent: (state) => {
			return (id) => {
				return state.event[id] ?? null;
			};
		},
		getIsPrivate: (state) => {
			return (id) => {
				return state.isPrivate[id] ?? null;
			};
		},
		getToken: (state) => {
			return (id) => {
				return state.token[id] ?? null;
			};
		},
	},

	actions: {
		fetch(id, slug, token) {
			return new Promise((resolve, reject) => {
				GuestEventRepository.getEvent(id, slug, token)
					.then((result) => {
						this.event[id] = result.data.data;
						this.isPrivate[id] = false;
						resolve(result);
					})
					.catch((result) => {
						if ("status" in result && result.status === 401) {
							this.isPrivate[id] = true;
						}
						reject(result);
					});
			});
		},
		verificationEmail(id, slug, email) {
			return new Promise((resolve, reject) => {
				GuestEventRepository.verificationEmail(id, slug, email)
					.then((result) => {
						this.token[id] = result.data.data.token;
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		verificationCode(id, code, token) {
			return new Promise((resolve, reject) => {
				GuestEventRepository.verificationCode(code, token)
					.then((result) => {
						this.token[id] = result.data.data.token;
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
	},
});
