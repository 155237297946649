import { ref } from "vue";
import { useGuestEventStore } from "@/stores/guest/useGuestEventStore.js";

const isLoading = ref(false);
const event = ref(null);
const isPrivate = ref(false);
const token = ref(false);

export default () => {
	const guestEventStore = useGuestEventStore();

	const getEvent = (id) => {
		return guestEventStore.getEvent(id);
	};

	const getIsPrivate = (id) => {
		return guestEventStore.getIsPrivate(id);
	};

	const getToken = (id) => {
		return guestEventStore.getToken(id);
	};

	const fetch = async (id, slug, token = "") => {
		isLoading.value = true;
		try {
			await guestEventStore.fetch(id, slug, token);
			event.value = getEvent(id);
			return event;
		} catch (e) {
			throw new Error(e);
		} finally {
			isPrivate.value = getIsPrivate(id);
			isLoading.value = false;
		}
	};

	const verificationEmail = async (id, slug, email) => {
		try {
			await guestEventStore.verificationEmail(id, slug, email);
			token.value = getToken(id);
			return token;
		} catch (e) {
			throw new Error(e);
		}
	};

	const verificationCode = async (id, code, oldToken) => {
		try {
			await guestEventStore.verificationCode(id, code, oldToken);
			token.value = getToken(id);
			return token;
		} catch (e) {
			throw new Error(e);
		}
	};

	return {
		isLoading,
		event,
		isPrivate,
		token,
		fetch,
		getEvent,
		getToken,
		verificationEmail,
		verificationCode,
	};
};
